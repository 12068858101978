<template>
  <div>
    <loader v-if="showLoader" />
    <div v-else style="min-height: 80svh">
      <p
        style="
          text-align: center;
          font-weight: 400;
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.1em;
          color: rgba(18, 43, 62, 0.7);
        "
        :style="
          $vuetify.breakpoint.xs ? ' margin-top: 50%;' : ' margin-top: 40vh;'
        "
      >
        {{ text }}
      </p>
    </div>
    <vue-html2pdf
      v-if="ticket !== null"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`Квиток ${ticket?.departure?.translations?.name} - ${
        ticket?.destination?.translations?.name
      } ${new Date(
        ticket?.schedule?.departed_at.substr(0, 10)
      ).toLocaleDateString('uk-UA', { month: 'short', day: 'numeric' })}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          :ticket="ticket"
          :isPdf="isPdf"
          :text="qrText"
          :isFront="true"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import Loader from "@/components/UI/Loader.vue";
import bookingTicketService from "../../requests/User/bookingTicketService";
import pdfTicket from "../../components/User/pdfTicket.vue";
export default {
  components: {
    VueHtml2pdf,
    pdfTicket,
    Loader,
  },
  data: () => ({
    downloadPDF: false,
    sendEmail: false,
    isPdf: true,
    isLoadingEmail: false,
    isLoadingPdf: false,
    showLoader: true,
    departure_city: {},
    arrival_city: {},
    base64: "",
    indexTicket: 0,
    ticket: null,
    text: "",
    qrText: "",
  }),
  mounted() {
    this.getOrderById();
  },
  methods: {
    async getOrderById() {
      await bookingTicketService
        .getOrderByQr(this.$route.params.order_id)
        .then((res) => {
          this.ticket = res.data;
          this.qrText = `${location.protocol}//${location.host}/order_qr/${this.ticket.uuid}`;
          if (this.ticket.status !== "Canceled") {
            setTimeout(() => {
              this.downloadQrToPDF();
            }, 2000);
          } else {
            this.text = "На жаль квиток вже не активний";
            this.showLoader = false;
          }
        });
    },
    // downloadQrToPDF() {
    //   this.downloadPDF = true;
    //   this.$refs.html2Pdf.generatePdf();
    //   this.text = "Ваш квиток успішно завантажено";
    //   this.showLoader = false;
    // },
    async downloadQrToPDF() {
      for (const [index] of this.ticket.tickets.entries()) {
        this.indexTicket = index;
        this.isPdf = true;
        this.isDownloadTicket = true;
        await this.$refs.html2Pdf.generatePdf();
        this.downloadPDF = true;
        await new Promise((resolve) => setTimeout(resolve, 500));
        this.text = "Ваш квиток успішно завантажено";
        this.showLoader = false;
        this.downloadPDF = false;
      }
    },
  },
};
</script>

<style>
</style>